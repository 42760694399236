import React,{useState} from "react";
import {   Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  Box,
  Radio,
  FormHelperText,
  RadioGroup,
  FormLabel,
  useMediaQuery,
  IconButton,
  CardMedia,
  CardHeader,
  Card,
  CardContent
 } from '@mui/material';
import { useSelector } from "react-redux";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { useTheme } from "@mui/material/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CallIcon from '@mui/icons-material/Call';
import Avatar from "@mui/material/Avatar";
import EmailIcon from "@mui/icons-material/Email";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import FeedbackIcon from '@mui/icons-material/Feedback';
import CssBaseline from "@mui/material/CssBaseline";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useForm } from "react-hook-form";

import OrderByWhatsapp from '../../components/OrderByWhatsapp';
import CountriesDropdown from "../../components/CountriesDropdown";
const defaultValues = {
  name: "",
  email: "",
  mobile: "",
};
const defaultTheme = createTheme();

function OrderByWhatsappPage() {
  const mediumViewport = useMediaQuery('(min-width:600px)');
  const [phoneValue,setPhoneValue] = useState();
  const form = useForm({ defaultValues });
  const { register, control, handleSubmit, formState, watch, reset, setValue } = form;
  const { errors, submitCount } = formState;
  const { ref } = register("user_type")
  const onSubmit = (data = { defaultValues }) => {
    console.log("form submitted", data);
  };
  const cartState = useSelector(state=>state.cart)
  console.log("order by whatsapp page",cartState)
  
  const watchName = watch("fullname");
  const watchMobile = watch("mobile")
  const watchDonorsAddress = watch("donors_address");

  const message = 
  `*Order Details*: 
    total books:${cartState.totalBooksDonated}
    total amount:${cartState.totalPrice.toFixed()}
*Delivery Address*:
    name: ${watchName}
    mobile:${watchMobile}
    ${watchDonorsAddress}
  `;
  const number = +917801066066
  return (
    <>
       <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <FeedbackIcon/>
          </Avatar>
          <Typography component="h1" variant="h5">
            Kindly fill the below details - to serve you better.
          </Typography>
      <Box component="form" noValidate sx={{ mt: 3, width:"100%" }}>
      <Grid container >
      <Grid item md={12} xs={12}>
        <Card>
          
        <CardHeader
        avatar = {
          <Avatar variant="square" src="https://topperzz-course-images.s3.amazonaws.com/A5aY4T9m2OVLIdRKTNrie.jpeg"/>
        }
        title= {`Total Bill = Rs.${cartState.totalPrice.toFixed()}`}
        titleTypographyProps={{
          fontSize:"15px",
          textAlign:"center"
        }}
        subheader = {`Total Books = ${cartState.totalBooksDonated}`}
        subheaderTypographyProps={{
          fontSize:"12px",
          textAlign:"center"
        }}
        />

        
        <CardMedia
        component = "img"
        height="420px"
        image = "https://topperzz-course-images.s3.amazonaws.com/LbC9-F_GLIaP2zGDf6ZVm.jpeg"
        />  
        <CardContent>
          <Typography>
            Scan the above QR Code and enter the total Bill. Or you can directly pay to the below number.
          </Typography>
          <Typography>
            PhonePe : 8523066066 | GooglePay : 8523066066 
          </Typography>
        </CardContent>
        </Card>
    
         </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            // onFocus={() => setShrink(true)}
            // onBlur={(e) => setShrink(!!e.target.value)}
            // InputLabelProps={{ sx: { ml: 0 }, shrink }}
            sx={{ width: "98%", fontSize:"18px" }}
            fullWidth
            //InputLabelProps={{style: {fontSize:12, color:"blue"}}} // font size of input label
            variant="outlined"
            margin="normal"
            placeholder="Enter your full name"
            label="Full Name"
            id="fullname"
            name="fullname"
            // InputProps={{
            //   endAdornment: (
            //     <InputAdornment position="end">
            //     <AccountCircleIcon/>
            //     </InputAdornment>
            //   )
            // }}
            InputProps={{
              style:{fontSize:15},
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleIcon/>
                </InputAdornment>
              )
            }}
            {...register("fullname", {
              required: {
                value: true,
                message: "name is required"
              },
            })}
            error={errors?.fullname?.message}
            helperText={errors?.fullname?.message}
          />
        </Grid> 
      </Grid>
      <Grid container>
        <Grid item md={12} xs={12}>
          <TextField
            sx={{ width: "98%" }}
            fullWidth
            InputProps={{
              style:{fontSize:15},
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              )
            }}
            label="Email"
            placeholder="Enter your email Id"
            id="email"
            name="email"
            variant="outlined"
            margin="normal"

            {...register("email", {
              required: {
                value: true,
                message: "Email is required"
              },
              pattern: {
                value:
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: "Invalid email format",
              },
            })}
            error={errors?.email?.message}
            helperText={errors?.email?.message}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            sx={{ width: "98%" }}
            fullWidth
            variant="outlined"
            margin="normal"
            label="mobile"
            placeholder="whatsapp number along with country code"
            id="mobile"
            name="mobile"
            InputProps={{
              style:{fontSize:15},
              startAdornment: (
                <InputAdornment position="start">
                  <CallIcon />
                </InputAdornment>
              )
            }}
            {...register("mobile", {
              required: {
                value: true,
                message: "Mobile number is required"
              },
              pattern: {
                value: /^(?=.*[0-9])[0-9]{10,12}$/,
                message: "Enter a valid mobile number"
              }
            })}
            error={errors?.mobile?.message}
            helperText={errors?.mobile?.message}
          />
          {/* <PhoneInput
          style={{width:"100%"}}
  international
  country={"in"}
  value={phoneValue}
  onChange={setPhoneValue}/> */}
          
        </Grid>
      </Grid>
      <Grid container>
        
      <Grid md={12} xs={12}>
                        <TextField
                          sx={{ width: mediumViewport ? "99%" : "98%" }}
                          fullWidth
                          multiline
                          maxRows={4}
                          rows={3}
                          variant="outlined"
                          margin="normal"
                          label="Enter your delivery address"
                          placeholder="Enter the delivery address where the books has to be delivered"
                          id="donors_address"
                          name="donors_address"
                          InputProps={{
                            style:{fontSize:15},
                            startAdornment: (
                              <InputAdornment position="start">
                                <ApartmentIcon />
                              </InputAdornment>
                            )
                          }}

                          {...register("donors_address", {
                            required: {
                              value: true,
                              message: "address is required"
                            },
                          })}
                          error={errors?.donors_address?.message}
                          helperText={errors?.donors_address?.message}
                        />
                      </Grid>
       
       
        
         <Grid item md={12} xs={12}>
         <OrderByWhatsapp message={message} number={number}>
        <IconButton>
         <WhatsAppIcon fontSize="large" sx={{color:"#128C7E"}}/>
        </IconButton>  
        <Typography sx={{fontSize:"15px"}}>
          Order By Whatsapp
        </Typography>
      </OrderByWhatsapp>
         </Grid>
        
       
      </Grid>


      </Box>

      </Box>
      </Container>
    </ThemeProvider> 
     
    </>

  )
}

export default OrderByWhatsappPage